<template>
  <!-- layout-container lesson 15 -->
  <div class="lesson55-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import OpeningSongPage from "./4_OpeningSongPage.vue";
import IntroductionPage from "./5_Introduction.vue";
import StoryPageOne from "./6_StoryPageOne.vue";
import RecognitionPage_an from "./7_RecognitionPage_an.vue";
import RecognitionPage_en from "./8_RecognitionPage_en.vue";
import RecognitionPage_in from "./9_RecognitionPage_in.vue";
import RecognitionPage_un from "./10_RecognitionPage_un.vue";
import RecognitionPage_vn from "./11_RecognitionPage_vn.vue";
import PracticePageOne from "./12_PracticePageOne.vue";
import StoryPageTwo from "./13_StoryPageTwo.vue";
import RecognitionPage_ang from "./14_RecognitionPage_ang.vue";
import RecognitionPage_eng from "./15_RecognitionPage_eng.vue";
import RecognitionPage_ing from "./16_RecognitionPage_ing.vue";
import RecognitionPage_ong from "./17_RecognitionPage_ong.vue";
import BasketballGame from "./18_BasketballGame.vue";
import PracticePageTwo from "./19_PracticePageTwo.vue";
import StoryPageThree from "./20_StoryPageThree.vue";
import TigerMachineGame from "./21_TigerMachineGame.vue";
import RobotJumpGame from "./21_RobotJumpGame.vue";
import PracticePageThree from "./22_PracticePageThree.vue";

import WritingPage from "./23_WritingPage.vue";
import WritingPage_1 from "./23_WritingPage_1.vue";
import PracticePageFour from "./24_PracticePageFour.vue";
import PracticePageFour_1 from "./24_PracticePageFour_1.vue";

import PinyinSummary from "./25_PinyinSummary.vue";
import RankMapPageTwo from "./26_RankMapPageTwo.vue";
import ByePage from "./27_ByePage.vue";
export default {
  name: "Lesson55",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    OpeningSongPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPage_an,
    RecognitionPage_en,
    RecognitionPage_in,
    RecognitionPage_un,
    RecognitionPage_vn,
    PracticePageOne,
    StoryPageTwo,
    RecognitionPage_ang,
    RecognitionPage_eng,
    RecognitionPage_ing,
    RecognitionPage_ong,
    PracticePageTwo,
    BasketballGame,
    StoryPageThree,
    TigerMachineGame,
    RobotJumpGame,
    PracticePageThree,
    WritingPage,
    WritingPage_1,
    PracticePageFour,
    PracticePageFour_1,
    PinyinSummary,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        OpeningSongPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPage_an,
        RecognitionPage_en,
        RecognitionPage_in,
        RecognitionPage_un,
        RecognitionPage_vn,
        PracticePageOne,
        StoryPageTwo,
        RecognitionPage_ang,
        RecognitionPage_eng,
        RecognitionPage_ing,
        RecognitionPage_ong,
        BasketballGame,
        PracticePageTwo,
        StoryPageThree,
        TigerMachineGame,
        RobotJumpGame,
        PracticePageThree,
        WritingPage,
        WritingPage_1,
        PracticePageFour,
        PracticePageFour_1,
        PinyinSummary,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson55-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>

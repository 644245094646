<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"
    :lessonNum="11" :layout="`small`"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'yellowColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          english: "an",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-an.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          english: "en",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-en.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          english: "in",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-in.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          english: "un",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-un.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "ün",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-vn.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "ang",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-ang.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "eng",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-eng.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "ing",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-ing.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "ong",
          image: require(`@/assets/img/14-Pinyin/chapter-07/pinyin-ong.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("PinyinPuzzleGame", {
        attrs: {
          optionList: _vm.optionList,
          answerList: _vm.answerList,
          menuItems: 6,
          menuSize: `small`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuItems="5" :menuSize="`small`"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren-3.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren-1.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren-2.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun-2.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun-1.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun-3.svg"),
            showObj: true,
          },
        ],
        
        // [
        //   {
        //     id: 1,
        //     image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/red-e1.svg"),
        //     showObj: true,
        //   },
        //   {
        //     id: 2,
        //     image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/red-u4.svg"),
        //     showObj: true,
        //   },
        //   {
        //     id: 3,
        //     image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/red-i1.svg"),
        //     showObj: true,
        //   },
        // ],
      ],

      answerList: [
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/5-Words/ren2.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-1-ren.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/kun4.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-2-kun.svg"),
        },
      
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/qingtian.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-3-qing.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/songshu.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-4-song.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/caoqun.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-5-qun.svg"),
        },
      
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["an","en", "in", "un", "ün"], //ü
      strokeTimesList: [4,3, 4, 4, 6],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-an-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-07/chapter-07-an.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-en-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-07/chapter-07-en.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-in-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-07/chapter-07-in.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-un-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-07/chapter-07-un.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-vn-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-07/chapter-07-vn.gif'),
        },
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-an.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-en.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-in.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-un.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-vn.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
        
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-55.mp3"),
      },
      timeStampList: [6, 8, 10.4, 12.6, 13.7, 15.3, 19.1, 22, 25.7, 28, 29.5, 33],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "an, en, in",
          },
          {
            id: 2,
            chineseWords: "un, ün",
          },
          {
            id: 3,
            chineseWords: "ang, eng, ing, ong",
          },
          {
            id: 4,
            chineseWords: "dàn, dàn（蛋）",
          },
          {
            id: 5,
            chineseWords: "kǒnglóng, kǒnglóng（恐龙）",
          },
          {
            id: 6,
            chineseWords: "sēnlín, sēnlín（森林）",
          },
          {
            id: 7,
            chineseWords: "làng, làng（浪）",
          },
          {
            id: 8,
            chineseWords: "làng tíng le.（浪停了）",
          },
          {
            id: 9,
            chineseWords: "an, en, in",
          },
          {
            id: 10,
            chineseWords: "un, ün",
          },
          {
            id: 11,
            chineseWords: "ang, eng, ing, ong",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>

<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuItems="6" :menuSize="`small`"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
[
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang-3.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/denglong.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-6-deng.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/denglong.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-7-long.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/jintian.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-8-jin.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/jintian.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-9-tian.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/pingpangqiu.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-10-ping.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/pingpangqiu.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-55/puzzle-11-pang.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>

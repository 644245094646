<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: [ "ang",   "eng", "ing","ong"], //ü
      strokeTimesList: [6, 5, 6,5],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-ang-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-08/chapter-08-ang.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-eng-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-08/chapter-08-eng.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-ing-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-08/chapter-08-ing.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-07/pinyin-ong-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-08/chapter-08-ong.gif'),
        },
        
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ang.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-eng.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ing.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ong.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>

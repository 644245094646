<template>
  <div class="recognition-page-one">
    <RecognitionPage :backgroundImage="bgImg" :wordObj="wordDetail" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/14-Pinyin/chapter-07/pinyin-eng.svg"),
      wordDetail: {
        lesson7: true,
        pinName: "",
        chineseName: "",
        englishName: "eng",
        songUrl: require("@/assets/audio/L1/2-Pinyin/eng.mp3"),
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>